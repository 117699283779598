.defaultRowStyle {
  @apply p-4 first:rounded-l-full last:rounded-r-full;
}

.latestEventsRowStyle {
  @apply p-2 first:rounded-l-full last:rounded-r-full text-first-text-color;
}

.timeColumn {
  width: 100px;
}

.iconColumn {
  width: 45px;
}

.searchColumn {
  width: 50px;
}

.search-header-column {
  @apply flex-row-reverse w-24 group relative hover:rounded-br-none;
}

.search-header-column:hover .filter-txt-box {
  z-index: 5;
}

.search-header-column-mobile {
  display: none !important;
}

.mobile-search-txt {
  opacity: 1 !important;
}

.search-header-column-mobile {
  display: none !important;
}

.actions-header-title {
  display: none;
}

.actions-header-column-mbl {
  display: flex;
  justify-content: center !important;
}
