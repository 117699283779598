@font-face {
  font-family: 'unicons';
  src: url('../assets/fonts/unicons.eot?45242598');
  src: url('../assets/fonts/unicons.eot?45242598#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/unicons.woff?45242598') format('woff'),
    url('../assets/fonts/unicons.ttf?45242598') format('truetype'),
    url('../assets/unicons.svg?45242598#unicons') format('svg');
  font-weight: normal;
  font-style: normal;
}

.uc-icon {
  font-family: 'unicons';
  font-style: normal;
  font-weight: normal;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.modal-wrapper {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  transition: 0.5s;
  height: 0%;
}

.modal-overlay {
  background-color: #000000c9;
}

.modal-open {
  height: 100%;
  z-index: 1000;
}

.modal-card {
  width: 0%;
  transition: 0.3s;
  transition-delay: 0.5s;
  opacity: 0;
}

.modal-card-open {
  width: 100% !important;
  opacity: 1;
}

@media (min-width: 768px) {
  .modal-card-open {
    width: 50% !important;
    opacity: 1;
  }
}
@media (min-width: 1200px) {
  .modal-card-open {
    width: 25% !important;
    opacity: 1;
  }
  .funding-event-wrapper {
    display: grid;
    grid-template-columns: 1fr 300px !important;
    grid-gap: 2rem;
  }
}

.funding-event-wrapper {
  display: grid;
  grid-template-columns: 1;
  grid-gap: 2rem;
}

.close-modal {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  position: absolute;
  top: 21px;
  right: 18px;
  background: rgba(49, 57, 71, 0.03);
  transition: background 0.2s ease-in;
  &:hover {
    background: rgba(49, 57, 71, 0.06);
  }
  &:focus {
    outline: none;
  }
  &:active {
    background: rgba(49, 57, 71, 0.03);
    outline: none;
  }
  .uc-icon {
    font-size: 22px;
  }
}

/* --------------skeleton-------------- */

.animated-background {
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #babdc0;
  background: linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 54%);
  background-size: 1000px 640px;
  position: relative;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
