@import '../../../styles/variables';

.stepper {
  .step {
    opacity: 0.5;

    &.active {
      opacity: 1;
    }

    .circle {
      width: 58px;
      height: 58px;
      margin-right: 7px;
      font-size: 18px;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: $secondary-highlight;
    }
  }
}

.register-form {
  max-width: 520px;
}
