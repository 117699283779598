@import "variables";

h1 {
  font-family: Hind;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 52px;
  color: $primary-brand;
  text-align: center;
  margin-bottom: 1.5rem;
}

h6 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 1.5rem;
}

.text-error {
  color: $primary-red;
}

.text-notice {
  color: $notice-color
}
