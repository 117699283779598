@import 'variables';

.form-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  .form-control {
    width: 100%;
  }

  .form-error {
    color: $primary-red;
    padding: 0 20px;
    line-height: 1.5;
    font-size: 14px;
  }

  input {
    border-radius: 40px;
    font-size: 16px;
    line-height: 1;
    padding: 20px;
    outline: none;
  }
}

// Removing default arrow
.select-box {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.filter-txt-box {
  z-index: -5;
}

.pagination-txt-box {
  padding: 10px 20px !important;
}

.tbl-search-header:hover .filter-txt-box {
  z-index: 5;
}

.pill-danger {
  @apply bg-pill-danger-bg border-pill-danger-bg text-pill-danger-color;
}

.pill-success {
  @apply bg-pill-success-bg border-pill-success-bg text-pill-success-color;
}

.pill-warning {
  @apply bg-pill-warning-bg border-pill-warning-bg text-pill-warning-color;
}

.pill {
  @apply px-4 py-1 border border-pill-border text-xs uppercase text-center text-pill-color rounded-2xl;
}

// input type checkbox

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $primary-brand;
}

input:focus + .slider {
  box-shadow: 0 0 1px $primary-brand;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
