.bear-chart-0 > *, .gopher-chart-0 > * {
    fill: #86CDB8;
    .eye {
      fill: #4B2A58
    }
    .mouth {
      fill: #00000000;
      stroke: #4B2A58;
    }
    .label {
      color: white;
      background-color: #86CDB8;
    }
  }

  .bear-chart-1 > *, .gopher-chart-1 > *  {
    fill: #4B2A58;
    .eye {
      fill: #FF718A
    }
    .mouth {
      fill: #00000000;
      stroke: #FF718A;
    }
    .label {
      color: white;
      background-color: #4B2A58;
    }
  }

  .bear-chart-2 > *, .gopher-chart-2 > *  {
    fill: #e84a5f;
    .eye {
      fill: #ffffff
    }
    .mouth {
      fill: #00000000;
      stroke: #ffffff;
    }
    .label {
      color: white;
      background-color: #e84a5f;
    }
  }

.snake-chart-0 > *   {
    .body {
        fill: transparent;
        stroke: #86CDB8;
    }
    .head {
        fill: #86CDB8
    }
    .eye {
      fill: #4B2A58
    }
    &.label {
      color: white;
      background-color: #86CDB8;
    }
  }

.snake-chart-1 > *   {
    .body {
        fill: transparent;
        stroke: #4B2A58;
    }
    .head {
        fill: #4B2A58
    }
    .eye {
      fill: #86CDB8
    }
    &.label {
      color: white;
      background-color: #4B2A58;
    }
  }

