@tailwind base;
@tailwind components;
@tailwind utilities;

/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&display=swap');
@import 'styles/variables';
@import 'styles/forms';
@import 'styles/typography';
@import 'styles/buttons';

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  color: $primary-brand;
  height: 100%;
  background-color: #fbe5d3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

.root,
#root,
#docs-root {
  --first-color: #feddc5;
  --second-color: #fcbf90;
  --third-color: #fee9d9;
  --fourth-color: #fee6d4;
  --fifth-color: #f5978a;
  --sixth-color: #6e3d81;
  --seven-color: #4b2a58;
  --ninth-color: #fdcda8;
  --tenth-color: #fcdbc3;
  --first-card-box-color: #fef4ec;
  --second-card-box-color: #fdd1b0;
  --first-text-color: #4b2a58;
  --second-text-color: #ffffff;
  --first-btn-color: #6c3484;
  --second-btn-color: #fb9b54;
  --third-btn-color: #fcac71;
  --fourth-btn-color: #f5978a;
}

.hide-content {
  opacity: 0;
}

.lock-section {
  position: fixed;
  overflow: hidden;
}

.scroll-bg {
  background: #0003;
}

input::-webkit-calendar-picker-indicator {
  display: none;
}

/* Sidebar custom scrollbar */
/* For Firefox */
div#sidebar-content{
  scrollbar-width: thin;
  scrollbar-color: #ffac70 #00000000;
}

/* Chrome, Edge, and Safari */
div#sidebar-content::-webkit-scrollbar {
  width: 6px;
}

div#sidebar-content::-webkit-scrollbar-track {
  background: #00000000;
}

div#sidebar-content::-webkit-scrollbar-thumb {
  background-color: #ffac70;
  border-radius: 20px;
  border: 1px solid #ffddc4;
}

// custom checkbox
[type="checkbox"]:checked,
[type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="checkbox"]:checked + label,
[type="checkbox"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #4B2A58;
}
[type="checkbox"]:checked + label:before,
[type="checkbox"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #4B2A58;
    background: #fff;
}
[type="checkbox"]:checked + label:after,
[type="checkbox"]:not(:checked) + label:after {
    content: '';
    width: 8px;
    height: 8px;
    background: #4B2A58;
    position: absolute;
    top: 5px;
    left: 5px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="checkbox"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

// Custom Radio Button
[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #4B2A58;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #4B2A58;
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 8px;
    height: 8px;
    background: #4B2A58;
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

// custom dropdown icon for select input
select.formSelect {
  -moz-appearance:none;
  -webkit-appearance:none;
  appearance:none;
  background: url("./assets/icons/ic-down.svg") no-repeat right center
}

// turns off the number input spin button
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.color-input-container input {
  opacity: 1 !important;
}

textarea {
  resize: none;
}

.one-line-clamp {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.two-line-clamp {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}