@import 'variables';

.btn {
  display: inline-flex;
  align-items: center;
  padding: 16px 20px;
  border-radius: 40px;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
  outline: none;

  &.btn-block {
    width: 100%;
  }

  &.btn-primary {
    background-color: $orange-highlight;
    color: $primary-brand;

    &:active {
      background-color: $orange-highlight-active;
    }
  }

  &:hover {
    opacity: 0.8;
  }
}

.btn:focus {
  outline: none;
}
