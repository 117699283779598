.section-toggle-btn {
  @apply text-left w-full flex text-gray-400 focus:outline-none
}

.section-toggle-btn-collapsed {
  @apply ml-0 items-start flex flex-col items-center
}

.section-toggle-btn-expanded {
  @apply ml-2 justify-between items-start
}

.label-sidebar-collapsed {
  text-orientation: revert;
  writing-mode: tb;
}

div#sidebar-content::-webkit-scrollbar {
  display: none;
}

div#sidebar-content{
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.section-in {
  transition: transform 0.6s ease-in;
  animation: enter-in-left 0.7s;
}


@keyframes enter-in-left {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.section-wrapper {
  overflow: hidden;
  transition: height 300ms;
}