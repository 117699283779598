@import 'variables';

input.flatpickr-input {
  background: transparent;
  outline: none;
  color: var(--seven-color);
  font-size: 16px;
  font-weight: 400;
  height: 24px;
  width: 100%;
}

input.flatpickr-input:disabled {
  background: transparent;
  outline: none;
  color: var(--seven-color);
  opacity: 0.5;
}

span.selected {
  background: $orange-highlight !important;
  border-color: $orange-highlight !important;
}

div.flatpickr-month {
  background: $orange-highlight !important;
}

select.flatpickr-monthDropdown-months{
  background: $orange-highlight !important;
  color: $primary-brand !important;
}

div.flatpickr-weekdays {
  background: $orange-highlight !important;
}

span.flatpickr-weekday {
  background: $orange-highlight !important;
}

option.flatpickr-monthDropdown-month {
  background-color: $orange-highlight !important;
  border: none !important;
}


.flatpickr-calendar.arrowTop:before {
  border-bottom-color: $orange-highlight !important;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: $orange-highlight !important;
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: $orange-highlight !important;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: $orange-highlight !important;
}

span.flatpickr-weekday {
  color: $primary-brand !important
}

input.numInput {
  color: $primary-brand !important;
}
