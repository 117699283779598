.top {
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  transition: transform 0.6s ease-in;
  animation:  message-in-top 0.7s;
}

.top-left {
  top: 12px;
  left: 12px;
  transition: transform 0.6s ease-in;
  animation: message-in-left 0.7s;
}

.top-right {
  top: 12px;
  right: 12px;
  transition: transform 0.6s ease-in-out;
  animation: message-in-right 0.7s;
}

.bottom-left {
  bottom: 12px;
  left: 12px;
  transition: transform 0.6s ease-in;
  animation: message-in-left 0.7s;
}

.bottom-right {
  bottom: 12px;
  right: 12px;
  transition: transform 0.6s ease-in-out;
  animation: message-in-right 0.7s;
}

.notification {
  background: #fff;
  transition: 0.3s ease;
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px;
  margin-bottom: 15px;
  max-width: 300px;
  min-width: 250px;
  max-height: 100px;
  border-radius: 3px 3px 3px 3px;
  box-shadow: 0 0 10px #999;
  color: #000;
  background-position: 15px;
  background-repeat: no-repeat;
}

@keyframes message-in-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes message-in-left {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes message-in-top {
  from {
    transform: translateY(-100%) translateX(-50%);
  }
  to {
    transform: translateY(0) translateX(-50%);
  }
}

.two-line-clamp {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}


